<template>
  <div class="relative container">
    <div class="watchSwiper overflow-hidden px-4 pt-3 pb-5 lg:pt-5">
      <div class="swiper-wrapper">
        <p-card
          :class="{ 'witdthCard': products.length === 1 }"
          v-for="item in products"
          :product="item"
          mode="tile"
          class="swiper-slide"
        >
        </p-card>
      </div>
      <div ref="prevEl" class="swiper-button-prev"></div>
      <div ref="nextEl" class="swiper-button-next"></div>
    </div>
  </div>
</template>

<script setup>
import { Swiper } from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/css/bundle";

Swiper.use([Navigation]);

const props = defineProps({
  products: { type: Array, default: [] },
});

onMounted(() => {
  const swiper = new Swiper(".watchSwiper", {
    // freeMode: true,
    spaceBetween: 16,
    // loop: true,
    slidesPerView: 1,
    modules: [Navigation],
    breakpoints: {
      320: {
        slidesPerView: 1.1,
      },
      640: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    },
    watchSlidesProgress: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  return { swiper };
});
</script>

<style scoped>
@media (max-width: 640px) {
  .witdthCard {
    width: 100%!important;
  }
}

@media (min-width: 1025px) {
  .swiper-button-prev {
    left: -1.6rem;
  }

  .swiper-button-next {
    right: -1.6rem;
  }
}

@media (max-width: 1024px) {
  .swiper-button-prev {
    left: -1.75rem;
  }

  .swiper-button-next {
    right: -1.75rem;
  }
}

@media (max-width: 641px) {
  .swiper-button-prev {
    visibility: hidden;
  }

  .swiper-button-next {
    visibility: hidden;
  }
}
.swiper-slide {
  opacity: 0;
}
.swiper-slide-visible {
  opacity: 1;
}
.watchSwiper [class^="swiper-button-"] {
  color: #3a405a;
  font-size: 5px;
}

.watchSwiper [class^="swiper-button-"]::after {
  font-size: 25px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1210px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
</style>
